
import { Options, Vue } from 'vue-class-component';
import { FootwearEstimationClient, GarmentClient, CompanyClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import AddNewSeasonModal from '../../modals/addNewSeasonModal.vue';
import AddNewGarmentModelModal from '../../modals/addNewGarmentModelModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Start extends Vue {

    selectedGarment: string = "";
    model: OM.CreateEstimationVM = new OM.CreateEstimationVM();
    footwearConfiguration: OM.FootwearConfiguration = new OM.FootwearConfiguration();

    companySeasonList: OM.TextIdentifier[] = [];
    companyModelList: OM.TextIdentifier[] = [];

    availableGenders: string[] = [];
    selectedFootwearType: string = "";
    selectedGender: string = "";
    selectedSize: string = "";
    selectedProcessType: string = "";
    rndtypes: number = 0;
    rndSizes: number = 0;
    rndProcesses: number = 0;

    @Watch('$route.params.garmentIdentifier')
    updateGarment() {
        this.init();
    }
    
    created() {
        this.init();
    }

    init() {
        Promise.all([
            GarmentClient.getGarmentTextIdentifier(this.$route.params.garmentIdentifier as string),
            GarmentClient.getFootwearConfiguration(this.$route.params.garmentIdentifier as string),
            CompanyClient.getSeasonsByCompany(),
            CompanyClient.getModelsByCompany()
        ])
        .then( xs => {
            this.model.garmentIdentifier = this.$route.params.garmentIdentifier as string;
            this.model.quantity = 1;
            this.selectedGarment = xs[0].text;
            this.footwearConfiguration = xs[1];

            this.footwearConfiguration.footwearTypes.forEach(footwearType => {
                footwearType.garmentConfigurationPropertyLayers.forEach(element => {
                    element.genderValues.forEach(gender => {
                        if(this.availableGenders.indexOf(gender) == -1)
                            this.availableGenders.push(gender);
                    });
                });
            })

            this.companySeasonList = xs[2];
            this.companyModelList = xs[3];
        })
    }

    get footwearTypes() {
        if(!this.selectedGender)
            return [];

        this.rndtypes = Math.random();
        return this.footwearConfiguration.footwearTypes.filter(x => x.garmentConfigurationPropertyLayers[0].genderValues.indexOf(this.selectedGender) > -1).map(x => x.name);
    }
    
    get selectedTypeConfiguration() {
        var filteredTypes = this.footwearConfiguration.footwearTypes.filter(x => x.name == this.selectedFootwearType);

        if(filteredTypes.length == 0)
            return null;
        else
            return filteredTypes[0];
    }

    // get availableGenders() {
    //     if(this.selectedTypeConfiguration == null)
    //         return [];

    //     this.rndGenders = Math.random();
    //     return this.selectedTypeConfiguration.garmentConfigurationPropertyLayers[0].genderValues;
    // }

    get availableSizes() {
        if(this.selectedTypeConfiguration == null || !this.selectedGender)
            return [];

        this.rndSizes = Math.random();
        var genderProperty = this.selectedTypeConfiguration.garmentConfigurationPropertyLayers[0].propertyValues.filter(x => x.gender == this.selectedGender);

        if(genderProperty.length == 0)
            return [];

        return genderProperty[0].values.map(x => x.label);
    }

    get availableProcessesType() {
        if(this.selectedTypeConfiguration == null || !this.selectedGender)
            return [];

        this.rndProcesses = Math.random();
        return this.selectedTypeConfiguration.footwearProcessTypes.map(x => x.name);
    }

    get disabled(){
        return !this.model.garmentIdentifier || !this.model.sku || this.model.quantity < 1 || !this.selectedFootwearType || !this.selectedGender || !this.selectedSize || !this.selectedProcessType || !this.model.seasonIdentifier || !this.model.garmentModelIdentifier;
    }

    addNewSeason() {
        this.$opModal.show(AddNewSeasonModal, {
            saveCallback: ((seasonName: string) => {
                CompanyClient.createNewSeason(seasonName)
                .then(x => {
                    var newTextIdForList = new OM.TextIdentifier();
                    newTextIdForList.identifier = x;
                    newTextIdForList.text = seasonName;
                    this.companySeasonList.push(newTextIdForList);
                    this.model.seasonIdentifier = x;

                    this.$opModal.closeLast();
                })
            })
        })
    }

    addNewModel() {
        this.$opModal.show(AddNewGarmentModelModal, {
            saveCallback: ((modelName: string) => {
                CompanyClient.createNewGarmentModel(modelName)
                .then(x => {
                    var newTextIdForList = new OM.TextIdentifier();
                    newTextIdForList.identifier = x;
                    newTextIdForList.text = modelName;
                    this.companyModelList.push(newTextIdForList);
                    this.model.garmentModelIdentifier = x;

                    this.$opModal.closeLast();
                })
            })
        })
    }

    createEstimation(){
        if(this.model.quantity < 1)
            this.model.quantity = 1;
        
        FootwearEstimationClient.createNewEstimation(this.model)
        .then(x => {
            var modelForUpdate = new OM.FootwearEstimationBaseInformationVM();
            modelForUpdate.identifier = x;
            modelForUpdate.footwearType = this.selectedFootwearType;
            modelForUpdate.gender = this.selectedGender;
            modelForUpdate.processType = this.selectedProcessType;
            modelForUpdate.propertyValue["Size"] = this.selectedSize;

            FootwearEstimationClient.updateBasicInfo(modelForUpdate)
            .then(x => {
                this.$router.push("/footwear-calculator/components-weights/" + modelForUpdate.identifier);
            })
        })
    }
}
